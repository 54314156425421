import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-guesthub-component-skeleton-loader',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {
  @Input() width: string = '100%';
  @Input() height: string = '100%';
  @Input() margin: string = '0.2rem 0';
  @Input() borderRadius: string = "0.4rem";

  constructor() { }

  ngOnInit() {
  }

}