import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyComponent } from './component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms'
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ComponentModalChangeImagenModule } from '../modal-change-imagen/module';
import { GuestHubSkeletonLoaderModule } from '../skeleton-loader/module';
import { GuesHubSelectDefaultIconModule } from './select-default-icon/module';

@NgModule({
    declarations: [
        MyComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        TooltipModule,
        ComponentModalChangeImagenModule,
        GuesHubSelectDefaultIconModule,
        GuestHubSkeletonLoaderModule
    ],
    exports: [
        MyComponent
    ],
    providers: [],
})
export class GuestHubInputImagesModule { }