import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, SimpleChanges } from "@angular/core";
import { ModalChangeImagenService } from './service';
import { HttpEventType } from "@angular/common/http";
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { ImageCroppedEvent, ImageCropperComponent, base64ToFile } from 'ngx-image-cropper';
declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-guesthub-component-modal-change-imagen',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {
  @Input() containerName = 'default';
  @Input() width: any;
  @Input() height: any;
  @Input() image: any;
  @Input() ratio: string;
  @Input() info: boolean;
  @Input() maxSize: number;
  @Output() onComplete = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() loading = new EventEmitter();
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  loadingImg = true;
  countEdit = 0;
  edited = false;
  loaderImgActive = false;
  cropImageComplet: any;
  zoom = '1.'
  transform = {
    scale: 0,
    rotate: 0,
    flipH: false,
    flipV: false
  }
  scale = 1;
  @Input() imageChangedEvent = '';
  croppedImage = '';
  canvasRotation = 0;

  constructor(
    private service: ModalChangeImagenService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image && changes.image.currentValue) {
      this.image = changes.image.currentValue
    }
  }

  ngOnInit() {
    fetch(this.image)
      .then(response => response.ok ? response.blob() : null)
      .then(response => {
        if (response && response.type != "image/svg+xml") {
          //this.image = response;
          this.loadingImg = false;
        } else {
          this.image = null;
          this.loadingImg = false;
        }
      }).catch(err => {
        this.image = null;
        this.loadingImg = false;
        console.error(err);
      });
  }

  private base64ToFileAuto(base64: string): File {
    const matches = base64.match(/^data:(.+);base64,(.*)$/);
    if (!matches || matches.length !== 3) {
      throw new Error('Base64 inválido o malformado');
    }

    const mimeType = matches[1]; // ej: image/png, application/pdf
    const extension = mimeType.split('/')[1] || 'bin'; // png, pdf, etc.
    const b64Data = matches[2];

    const byteString = atob(b64Data);
    const byteArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    const fileName = `${Date.now()}.${extension}`;

    return new File([byteArray], fileName, { type: mimeType });
  }

  ImgResponse(e) {
    this.loadingImg = true;
    this.loading.emit("0%");

    let input = new FormData();

    input.append('web', this.base64ToFileAuto(e));
    input.append("containerName", this.containerName);
    this.service.uploadImgs(input)
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.loading.emit(Math.round(event.loaded / event.total * 100) + "%");
        } else if (event.type === HttpEventType.Response) {
          this.loading.emit(null);
          this.onComplete.emit({
            urlToPreview: event.body.urlToPreview,
            saveToDatabase: event.body.saveToDatabase,
            id: event.body.id
          });
          this.image = event.body.urlToPreview;
        } else {
        }
      }, err => {
        this.loading.emit(null);
        this.onError.emit(err.error);
      });
  }

  editImg(e) {
    this.countEdit++
    if (this.countEdit > 1) {
      this.edited = true;
    } else {
      this.edited = false;
    }
  }

  closeModalEvent() {
    this.loading.emit(null);
    this.closeModal.emit(true);
  }


  fileChangeEvent(event: any): void {
    if (this.maxSize && event.target.files[0].size > this.maxSize * 1024 * 1024) {
      this.onError.emit("Uploaded File Exceeds Max Size");
      return;
    }
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.service.activeEditImg$.emit(true);
    //this.imageChangedEvent = event;
    console.log(base64ToFile(event.base64));
    this.croppedImage = event.base64;
    this.editImg(event?.base64)
  }

  imageLoaded(e) {
    this.loaderImgActive = true;
    //this.original.emit(e?.original?.base64)
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed(e) {
    // show message
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  save() {
    this.ImgResponse(this.croppedImage);
    //this.complete.emit(this.cropImageComplet);
    this.imageChangedEvent = '';
    this.croppedImage = '';
    $("#modal-img").css('height', '700px !important')
  }

  changesZoom(e) {
    this.scale = e;
    this.transform = {
      ...this.transform,
      scale: e
    };
  }

  zoomIn() {
    if (this.scale < 3) {
      this.scale += .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  zoomOut() {
    if (this.scale > 1) {
      this.scale -= .1
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }
}