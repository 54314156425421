import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlBuilderService } from '../../services/url-builder.service';
import { Auth0Service } from "../../auth0.service";
import { environment } from "../../../environments/environment";

@Injectable()
export class SettingsOutletService {

	constructor(
		private http: HttpClient,
		private urlbuilder: UrlBuilderService,
		private auth: Auth0Service
	) { }

	private calculateTypeOutlet(outleType: string): string {
		return outleType === 'restaurant'
			? 'restaurants'
			: outleType === 'service'
				? 'services'
				: outleType === 'promotion'
					? 'promotions'
					: outleType === 'room-service'
						? 'room-services'
						: '';
	}

	getImgsDefault() {
		return this.http
			.get<any>(`${this.urlbuilder.reservationsApi
				.concat("/properties/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/guestapp-features-images")}`)
			.toPromise();
	}

	listOutletSelectedForRoomServices(outleType: string): Promise<any> {
		return this.http.get<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(this.calculateTypeOutlet(outleType))
			.concat("/room-service")}`).toPromise();
	}

	listOutlet(outleType: string): Promise<any> {
		return this.http.get<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(this.calculateTypeOutlet(outleType))}`).toPromise();
	}

	autoSavedTermsAndConditions(outleType: string, outletId: string, contents) {
		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat(outletId)
			.concat("/settings/terms-and-conditions")}`, contents).toPromise();
	}

	getAllTermsAndConditions(outleType: string, outletId: string) {
		return this.http.get<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat(outletId)
			.concat(`/settings/terms-and-conditions`)}`).toPromise();
	}

	searchStatuses(e: any, ommiting: any[]) {
		return this.http.get<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/statuses").concat(
				`${e
					? `?q=${encodeURIComponent(e)}${ommiting && ommiting.length > 0 ? `&ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
					: `${ommiting && ommiting.length > 0 ? `?ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
				}`
			)}`).toPromise();
	}

	addNewStatus(data) {
		data.creation = {
			id: this.auth.userProfile.id,
			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
			picture: this.auth.userProfile.picture,
			date: new Date()
		};
		return this.http.post<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/status")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise()
			.then(response => Object.assign({}, data, response));
	}

	searchCompanies(e: any, ommiting: any[]) {
		return this.http.get<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/companies").concat(
				`${e
					? `?q=${encodeURIComponent(e)}${ommiting && ommiting.length > 0 ? `&ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
					: `${ommiting && ommiting.length > 0 ? `?ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
				}`
			)}`).toPromise();
	}

	addNewCompany(data) {
		data.creation = {
			id: this.auth.userProfile.id,
			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
			picture: this.auth.userProfile.picture,
			date: new Date()
		};
		return this.http.post<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/company")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise()
			.then(response => Object.assign({}, data, response));
	}

	searchGroups(e: any, ommiting: any[]) {
		return this.http.get<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/groups").concat(
				`${e
					? `?q=${encodeURIComponent(e)}${ommiting && ommiting.length > 0 ? `&ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
					: `${ommiting && ommiting.length > 0 ? `?ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
				}`
			)}`).toPromise();
	}

	addNewGroup(data) {
		data.creation = {
			id: this.auth.userProfile.id,
			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
			picture: this.auth.userProfile.picture,
			date: new Date()
		};
		return this.http.post<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/group")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise()
			.then(response => Object.assign({}, data, response));
	}

	searchRateCodes(e: any, ommiting: any[]) {
		return this.http.get<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/rateCodes").concat(
				`${e
					? `?q=${encodeURIComponent(e)}${ommiting && ommiting.length > 0 ? `&ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
					: `${ommiting && ommiting.length > 0 ? `?ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
				}`
			)}`).toPromise();
	}

	addNewRateCode(data) {
		data.creation = {
			id: this.auth.userProfile.id,
			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
			picture: this.auth.userProfile.picture,
			date: new Date()
		};
		return this.http.post<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/rateCode")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise()
			.then(response => Object.assign({}, data, response));
	}

	searchVipCodes(e: any, ommiting: any[]) {
		return this.http.get<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/vipCodes").concat(
				`${e
					? `?q=${encodeURIComponent(e)}${ommiting && ommiting.length > 0 ? `&ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
					: `${ommiting && ommiting.length > 0 ? `?ommiting=${ommiting.map(o => encodeURIComponent(o.name)).join(',')}` : ``}`
				}`
			)}`).toPromise();
	}

	addNewVipCode(data) {
		data.creation = {
			id: this.auth.userProfile.id,
			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
			picture: this.auth.userProfile.picture,
			date: new Date()
		};
		return this.http.post<any>(`${this.urlbuilder.communicationsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/workflow/vipCode")}`, Object.assign({}, data, { userId: this.auth.userProfile.id })).toPromise()
			.then(response => Object.assign({}, data, response));
	}

	changeEnabledDisabledMenuContent(outleType: string, menu: any) {
		menu.modifications = menu.modifications ? menu.modifications : [];
		menu.modifications.push({
			id: this.auth.userProfile.id,
			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
			picture: this.auth.userProfile.picture,
			date: new Date()
		});
		if (menu.modifications.length > 3) {
			menu.modifications.splice(0, menu.modifications.length - 3);
		}

		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat("menu")
			.concat("/content/")
			.concat(menu._id)}`, {
			enabled: menu.enabled,
			modifications: menu.modifications
		}).toPromise();
	}

	duplicateMenu(menu) {
		return this.http.post<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(menu.outleType)
			.concat("/")
			.concat("menu")
			.concat("/")
			.concat("duplicate")
			.concat("/")
			.concat(menu._id)}`,
			{
				creation: {
					id: this.auth.userProfile.id,
					name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
					picture: this.auth.userProfile.picture,
					date: new Date()
				}
			}
		).toPromise();
	}

	duplicateOutlet(outlet) {
		return this.http.post<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outlet.outleType)
			.concat("/")
			.concat(outlet._id)
			.concat("/")
			.concat("duplicate")}`,
			{
				creation: {
					id: this.auth.userProfile.id,
					name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
					picture: this.auth.userProfile.picture,
					date: new Date()
				}
			}
		).toPromise();
	}


	changeEnabledDisabledMenu(outleType: string, menu: any) {
		menu.modifications.push({
			id: this.auth.userProfile.id,
			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
			picture: this.auth.userProfile.picture,
			date: new Date()
		});
		if (menu.modifications.length > 3) {
			menu.modifications.splice(0, menu.modifications.length - 3);
		}

		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat("menu")
			.concat("/")
			.concat(menu._id)}`, {
			enabled: menu.enabled,
			modifications: menu.modifications
		}).toPromise();
	}

	deleteOutletContent(outleType: string, outletId: string, id: string) {
		return this.http.delete<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat(outletId)
			.concat("/content/")
			.concat(id)}`).toPromise();
	}

	deleteOutlet(outleType: string, id: string) {
		return this.http.delete<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat(id)}`).toPromise();
	}

	getOutlet(outleType: string, _id: string): any {
		return this.http.get<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat(_id ? _id : 'null')}`).toPromise();
	}

	getSettingsGuestApp() {
		return this.http.get<any>(this.urlbuilder.guestApp.getSettings())
			.toPromise();
	}

	getSettingsGuestAppSectionByCodename(outleType: string) {
		let codename;
		switch (outleType) {
			case 'service':
				codename = 'service.list'
				break;
			case 'room-service':
				codename = 'room-service.list'
				break;
			case 'promotion':
				codename = 'promotions.list'
				break;
			default:
				codename = 'restaurants.list';
				break;
		}
		return this.http.get<any>(`${this.urlbuilder.reservationsApi
			.concat(`/properties/`)
			.concat(`${this.auth.getChosenProperty.toString()}`)
			.concat(`/guestapp-feature/by-codename/`)
			.concat(`${codename}`)}`)
			.toPromise();
	}

	getDepartments() {
		return this.http
			.get<any>(this.urlbuilder.requestConfiguration.departments.list())
			.toPromise();
	}

	getDepartment(id: number) {
		return this.http.get<any>(this.urlbuilder.requestConfiguration.departments.get(id))
			.toPromise();
	}

	deleteSectionItem(outleType: string, idMenu: string, idSection: string, id: string) {
		return this.http.delete<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat("menu")
			.concat("/")
			.concat(idMenu)
			.concat("/section/")
			.concat(idSection)
			.concat("/item/")
			.concat(id)}`).toPromise();
	}

	deleteSection(outleType: string, idMenu: string, id: string) {
		return this.http.delete<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat("menu")
			.concat("/")
			.concat(idMenu)
			.concat("/section/")
			.concat(id)}`).toPromise();
	}

	deleteModifier(outleType: string, idMenu: string, id: string) {
		return this.http.delete<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat("menu")
			.concat("/")
			.concat(idMenu)
			.concat("/modifier/")
			.concat(id)}`).toPromise();
	}

	autoSavedSectionItem(outleType: string, idMenu: string, idSection: string, item) {
		if (item._id) { // Is update
			item.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});
			if (item.modifications.length > 3) {
				item.modifications.splice(0, item.modifications.length - 3);
			}

			return this.http.put<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")
				.concat("/")
				.concat(idMenu)
				.concat("/section/")
				.concat(idSection)
				.concat("/item/")
				.concat(item._id)}`, item).toPromise()
				.then(() => Promise.resolve(item));
		} else { // Is create
			item.creation = {
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			};

			return this.http.post<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")
				.concat("/")
				.concat(idMenu)
				.concat("/section/")
				.concat(idSection)
				.concat("/item")}`, item).toPromise()
				.then(response => {
					return Object.assign({}, item, response);
				});
		}
	}

	autoSavedSection(outleType: string, idMenu: string, section) {
		if (section._id) { // Is update
			section.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});
			if (section.modifications.length > 3) {
				section.modifications.splice(0, section.modifications.length - 3);
			}

			return this.http.put<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")
				.concat("/")
				.concat(idMenu)
				.concat("/section/")
				.concat(section._id)}`, section).toPromise()
				.then(() => Promise.resolve(section));
		} else { // Is create
			section.creation = {
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			};

			return this.http.post<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")
				.concat("/")
				.concat(idMenu)
				.concat("/section")}`, section).toPromise()
				.then(response => {
					return Object.assign({}, section, response);
				});
		}
	}

	autoSavedModifier(outleType: string, idMenu: string, modifier, idSection?: string, idItem?: string) {
		if (modifier._id) { // Is update
			modifier.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});
			if (modifier.modifications.length > 3) {
				modifier.modifications.splice(0, modifier.modifications.length - 3);
			}

			return this.http.put<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")
				.concat("/")
				.concat(idMenu)
				.concat("/modifier/")
				.concat(modifier._id)}`, modifier).toPromise()
				.then(() => Promise.resolve(modifier));
		} else { // Is create
			modifier.creation = {
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			};

			return this.http.post<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")
				.concat("/")
				.concat(idMenu)
				.concat("/modifier")}`, modifier).toPromise()
				.then(response => {
					return Object.assign({}, modifier, response);
				});
		}
	}

	autoSavedMenu(outleType: string, menu) {
		if (menu._id) { // Is update
			menu.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});
			if (menu.modifications.length > 3) {
				menu.modifications.splice(0, menu.modifications.length - 3);
			}

			return this.http.put<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")
				.concat("/")
				.concat(menu._id)}`, menu).toPromise()
				.then(() => Promise.resolve(menu));
		} else { // Is create
			menu.creation = {
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			};

			return this.http.post<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat("menu")}`, menu).toPromise()
				.then(response => {
					return Object.assign({}, menu, response);
				});
		}
	}

	autoSaved(outleType: string, outletDetail) {
		if (outletDetail._id) { // Is update
			outletDetail.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});
			if (outletDetail.modifications.length > 3) {
				outletDetail.modifications.splice(0, outletDetail.modifications.length - 3);
			}

			return this.http.put<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)
				.concat("/")
				.concat(outletDetail._id)}`, outletDetail).toPromise()
				.then(() => Promise.resolve(outletDetail));
		} else { // Is create
			outletDetail.creation = {
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			};
			return this.http.post<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(outleType)}`, outletDetail).toPromise()
				.then(response => {
					return Object.assign({}, outletDetail, response);
				});
		}
	}

	changeEnabledDisabledSectionGuestapp(sectionId: string, status: { enabled: boolean, canEnabled: boolean }) {
		return this.http.put<any>(`${this.urlbuilder.reservationsApi
			.concat("/properties/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/guestapp-feature/")
			.concat(sectionId)
			.concat('/enabled-disabled')}`, Object.assign({}, status, {
				userId: this.auth.userProfile.id
			})).toPromise();
	}

	autoSavedEnabledDisabled(outleType: string, outletId: string, status: { enabled: boolean, canEnabled: boolean }) {
		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat(outletId)
			.concat('/')
			.concat(status.enabled ? 'enabled' : 'disabled')}`, status).toPromise();
	}

	autoSavedContents(outleType: string, outletId: string, contents) {
		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat(outletId)
			.concat('/contents')}`, contents).toPromise();
	}

	setOutletsAndContentsInRoomServices(outleType: string = 'room-service', outlets) {
		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat('/selected')}`, outlets).toPromise();
	}

	getPropertyInformation() {
		return this.http.get<any>(`${this.urlbuilder.subscriptionApi
			.concat("/property/")
			.concat(
				this.auth.getChosenProperty.toString()
			)}`).toPromise();
	}

	getQrGeneral(outleType: string = 'restaurant') {
		return this.http.get<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(this.calculateTypeOutlet(outleType))
			.concat("/qr-general")}`).toPromise()
			.then(response => Object.assign({}, response, { propertyName: this.auth.userProfile.properties.find(p => p.id == parseInt(this.auth.getChosenProperty.toString())).name }));
	}

	updateOrderOutlet(outleType: string = 'restaurant', outlets) {
		// outlets = outlets.map(outlet => {
		// 	if (outlet.updated) {
		// 		outlet.modifications.push({
		// 			id: this.auth.userProfile.id,
		// 			name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
		// 			picture: this.auth.userProfile.picture,
		// 			date: new Date()
		// 		});

		// 		if (outlet.modifications.length > 3) {
		// 			outlet.modifications.splice(0, outlet.modifications.length - 3);
		// 		}
		// 	}

		// 	return outlet;
		// });

		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(this.calculateTypeOutlet(outleType))
			.concat("/")
			.concat('order')}`, outlets.map(outlet => ({
				_id: outlet._id,
				numOrder: outlet.numOrder,
				modifications: outlet.modifications
			}))).toPromise();
	}

	getMetaData(url: string) {
		return this.http.get<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/get-link-metadata")}?url=${url}`).toPromise();
	}

	getMenu(outleType: string, _id: string): any {
		return this.http.get<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/menu/")
			.concat(_id)}`).toPromise();
	}

	listModifiersByMenuId(type: string = 'restaurant', id: string) {
		return this.http
			.get<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(type)
				.concat("/menu/")
				.concat(id)
				.concat("/modifiers")}`)
			.toPromise();
	}

	listMenus(type: string = 'restaurant') {
		return this.http
			.get<any>(`${this.urlbuilder.outletsApi
				.concat("/")
				.concat(
					this.auth.getChosenProperty.toString()
				)
				.concat("/")
				.concat(type)
				.concat("/menus")}`)
			.toPromise();
	}

	updateOrderMenuSectionItems(outleType: string = 'restaurant', idMenu: string, idSection: string, items) {
		items = items.map(item => {
			item.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});

			if (item.modifications.length > 3) {
				item.modifications.splice(0, item.modifications.length - 3);
			}

			return item;
		});

		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/menu")
			.concat("/")
			.concat(idMenu)
			.concat("/section/")
			.concat(idSection)
			.concat("/items")
			.concat('/order')}`, items.map(section => ({
				_id: section._id,
				numOrder: section.numOrder,
				modifications: section.modifications
			}))).toPromise();
	}

	updateOrderMenuSections(outleType: string = 'restaurant', idMenu: string, sections) {
		sections = sections.map(section => {
			section.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});

			if (section.modifications.length > 3) {
				section.modifications.splice(0, section.modifications.length - 3);
			}

			return section;
		});

		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/menu")
			.concat("/")
			.concat(idMenu)
			.concat("/sections")
			.concat('/order')}`, sections.map(section => ({
				_id: section._id,
				numOrder: section.numOrder,
				modifications: section.modifications
			}))).toPromise();
	}

	updateOrderMenu(outleType: string = 'restaurant', menus) {
		menus = menus.map(menu => {
			menu.modifications.push({
				id: this.auth.userProfile.id,
				name: this.auth.userProfile.firstName + " " + this.auth.userProfile.lastName,
				picture: this.auth.userProfile.picture,
				date: new Date()
			});

			if (menu.modifications.length > 3) {
				menu.modifications.splice(0, menu.modifications.length - 3);
			}

			return menu;
		});

		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/menus")
			.concat('/order')}`, menus.map(menu => ({
				_id: menu._id,
				numOrder: menu.numOrder,
				modifications: menu.modifications
			}))).toPromise();
	}

	updateModifiersApplyToItems(outleType: string = 'restaurant', menuId: string, modifierId: string, items) {
		return this.http.put<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/menu/")
			.concat(menuId)
			.concat("/modifier/")
			.concat(modifierId)
			.concat("/apply-to-items")}`, items).toPromise();
	}

	deleteMenu(outleType: string, id: string) {
		return this.http.delete<any>(`${this.urlbuilder.outletsApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			)
			.concat("/")
			.concat(outleType)
			.concat("/")
			.concat("menu")
			.concat("/")
			.concat(id)}`).toPromise();
	}

	uploadFiles(data) {
		data.append('userId', this.auth.userProfile.id);
		return this.http.post<any>(`${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/files/${this.auth.getChosenProperty.toString()}`, data, {
			reportProgress: true,
			observe: 'events'
		});
	}

	uploadImgs(data) {
		data.append('userId', this.auth.userProfile.id);
		return this.http.post<any>(`${environment.PROTOCOL.concat(environment.BACKENDFILES.HOST).concat(environment.BACKENDFILES.DOMAIN)}/images/${this.auth.getChosenProperty.toString()}`, data, {
			reportProgress: true,
			observe: 'events'
		});
	}

	getNotMoreModal(arg0: string) {
		if (localStorage.getItem('no-more-modals')) {
			return JSON.parse(localStorage.getItem('no-more-modals')).find(m => m == arg0) ? true : false;
		} else {
			return false;
		}
	}

	setNotMoreModal(arg0: string) {
		if (localStorage.getItem('no-more-modals')) {
			let m = JSON.parse(localStorage.getItem('no-more-modals'));
			m.push(arg0);
			localStorage.setItem('no-more-modals', JSON.stringify(m));
		} else {
			localStorage.setItem('no-more-modals', JSON.stringify([arg0]));
		}
	}

	getAllIntegrations(): any {
		return this.http.get<any>(`${this.urlbuilder.appApi
			.concat("/")
			.concat(
				this.auth.getChosenProperty.toString()
			).concat("/integrations/available")
			}`).toPromise();
	}

}
