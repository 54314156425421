<app-requests-preview-update class="modal_right_0 white up_right mygavete"
  *ngIf="otherAction && otherAction === 'update'" [request]="request" (output)="otherAction = null" [users]="users"
  (otherActionEvent)="otherActionEvent($event)" [disabledButton]="disabledButtonUpdateAll"
  (cancelEvent)="otherAction = null"></app-requests-preview-update>

<app-requests-preview-update-finished-returned class="modal_right_0 white up_right mygavete"
  *ngIf="otherAction && otherAction == 'finished-returned'" [request]="request" (output)="openReturnedConfirm($event)"
  (otherActionEvent)="otherActionEvent($event)" (otherActionEvent)="otherActionEvent($event)"
  [title]="'Finish returned request'" [disabledButton]="disabledButton"
  (cancelEvent)="otherAction = null"></app-requests-preview-update-finished-returned>

<app-requests-preview-guest-information class="modal_right_0 white up_right mygavete"
  *ngIf="otherAction && otherAction.type == 'guest-information'" [request]="request" (output)="otherAction = null"
  (cancelEvent)="otherAction = null"></app-requests-preview-guest-information>

<app-requests-list-recyclerview-validate class="modal_right_0 white up_right mygavete"
  *ngIf="otherAction && otherAction.type == 'validate'" [request]="request" (output)="otherAction = null"
  (cancelEvent)="otherAction = null"></app-requests-list-recyclerview-validate>

<div class="modal_right_0 white up_right mygavete" *ngIf="!otherAction">
  <div class="gavete-header">
    icono
  </div>

  <div class="gavete-buttons">
    <botones>a</botones>
    <botones>b</botones>
    <botones>c</botones>
  </div>

  <div class="scrolling-gavete">
    <ng-container *ngFor="let a of arrray">
      hola<br />
    </ng-container>
  </div>

  <div class="gavete-footer">
    <button>dawd</button>
  </div>

  <!-- <div class="modal-content white" *ngIf="!loading">
    <div class="col s12 modal-margin-top modal-padding-leftright mod__mob">
      <i class="material-icons right pointer color-base-two cancel-icon" (click)="closeModal()">clear</i>
    </div>

    <div class="modal-title-container modal-padding-leftright"
      style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
      <h2 class="color-base font24 blod mod__mob marg-top" style="margin-bottom: 5px; flex-grow: 1;">
        {{ 'Request' | translate }} #{{ request.idReference }}
      </h2>
      <div *ngIf="request.state == 'active'" class="modal-dropdown-options" style="flex-shrink: 0;">
        <div class="my-dropdown" style="position: relative;">
          <a class="pointer dropdown-trigger" (click)="showDropdownMoreOptions[i] = !showDropdownMoreOptions[i]"
            data-target='dropdown1'>
            <i class="material-icons icon__edit">more_vert</i>
          </a>

          <div class="hidden-backgound" *ngIf="showDropdownMoreOptions[i]"
            (click)="showDropdownMoreOptions[i] = false;"></div>
          <ul id='dropdown1' class='dropdown-content' [ngClass]="{'inDropDown':showDropdownMoreOptions[i]}">
            <li class="cont-li">
              <a class="txt-dropdown miniMenu" (click)="otherAction = 'update'; showDropdownMoreOptions[i] = false;"
                href="javascript:;">
                <i class="material-icons icon-margin">edit</i>
                {{ 'Edit' | translate}}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col s12 padding0 modal-padding-leftright mod__mob" style="height: 60px;">
      <ul class="cont__mb__ul">
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='detail'"
          [ngClass]="{'active':tabSelected=='detail'}">
          {{ 'Detail' | translate }}
        </li>
        <li class="tab col s3 pointer comment-he mb__li__cont" (click)="tabSelected='comments'"
          [ngClass]="{'active':tabSelected=='comments'}">
          {{ 'Messages' | translate }}
          <span class="right notification-red" *ngIf="countComments() > 0">{{ countComments() }}</span>
        </li>
        <li class="tab col s3 pointer mb__li__cont" (click)="tabSelected='history'"
          [ngClass]="{'active':tabSelected=='history'}">
          {{ 'History' | translate }}
        </li>
        <!- <li class="donwload__icon pointer" (click)="exportPDF()">
          <i class="material-icons icon__down">download</i>
        </li>  ->
      </ul>
    </div>

    <div class="clearfix"></div>
  </div>

  <div class="modal-content" [ngSwitch]="tabSelected" style="height: 100%;" *ngIf="!loading">
    <app-requests-manager-preview-detail *ngSwitchCase="'detail'" [request]="request" [users]="users"
      [countSumate]="countSumate" (otherActionEvent)="otherActionEvent($event)">
    </app-requests-manager-preview-detail>
    <app-requests-manager-preview-comments *ngSwitchCase="'comments'" (onImgClick)="modalPreviewImg = $event"
      (eventDeleteComment)="modalDeleteComment = $event;" [request]="request" [users]="users" [comments]="request.comments">
    </app-requests-manager-preview-comments>
    <app-requests-manager-preview-history *ngSwitchCase="'history'" [id]="request.id"
      [reservationVip]="request.reservationVip" [history]="request.history">
    </app-requests-manager-preview-history>
    <div class="clearfix"></div>
  </div> -->

  <div class="cols12 l12 xl12 m12 center" *ngIf="loading">
    <div class="col s12">
      <br><br>
    </div>
    <div class="loadingdiv">
      <div class="preloader-wrapper big active">
        <div class="spinner-layer spinner-green-only">
          <div class="circle-clipper left">
            <div class="circle"></div>
          </div>
          <div class="gap-patch">
            <div class="circle"></div>
          </div>
          <div class="circle-clipper right">
            <div class="circle"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="modal-overlay pointer ups" (click)="closeModal()"></div>

<app-guesthub-modal-preview-img *ngIf="modalPreviewImg" [img]="modalPreviewImg"
  (close)="modalPreviewImg = null"></app-guesthub-modal-preview-img>

<app-guesthub-component-modal-confirmed *ngIf="modalDeleteComment" [config]="configModalDeleteComment"
  (confirm)="deleteComment(modalDeleteComment)"
  (cancel)="modalDeleteComment = null"></app-guesthub-component-modal-confirmed>

<app-guesthub-component-modal-confirmed *ngIf="modalConfirmDeclined" [config]="configModalConfirmDeclined"
  (confirm)="updateStatusDiscard()" (cancel)="modalConfirmDeclined = null"></app-guesthub-component-modal-confirmed>

<app-guesthub-component-modal-confirmed *ngIf="modalConfirmUpdateAll" [config]="configModalConfirmUpdateAll"
  (confirm)="saveUpdateAll()"
  (cancel)="modalConfirmUpdateAll = false; disabledButtonUpdateAll = false; configModalConfirmUpdateAll.config = undefined"></app-guesthub-component-modal-confirmed>