import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SettingsOutletService } from "../../../settings/outlet/service";

@Component({
  selector: 'app-guesthub-select-default-icon',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss']
})
export class MyComponent implements OnInit {
  @Input() img;
  @Output() update = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();
  @Output() uploadOtherImagen = new EventEmitter<any>();
  loading = true;
  imgesList: Array<any> = [];
  selected: any;

  constructor(
    private service: SettingsOutletService  ) { }

  ngOnInit() {
    this.loading = true;
    this.service.getImgsDefault().then(images => {
      this.imgesList = images;
      this.selected = this.img.img || null;
      this.loading = false;
    });
  }

  saveImg() {
    if (this.selected) {
      const img = this.imgesList.find(i => i.img == this.selected);
      if (img) {
        this.img.img = img.img;
        this.img.imgUrl = img.href;
      }
      this.update.emit(this.img);
      this.closeModal.emit(true);
    }
  }

  uploadOtherImagenEvent() {
    this.uploadOtherImagen.emit(true);
    this.closeModalEvent();
  }

  closeModalEvent() {
    this.closeModal.emit(true);
  }
}
