import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MyComponent } from "./component";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { RouterModule } from "@angular/router";
import { SettingsOutletService } from "../../../settings/outlet/service";

@NgModule({
  declarations: [MyComponent],
  imports: [
    CommonModule,
    TranslateModule,
    TooltipModule,
    RouterModule
  ],
  exports: [MyComponent],
  providers: [SettingsOutletService],
})
export class GuesHubSelectDefaultIconModule { }
