import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-guesthub-component-monitor-result',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {

  @Input() title: string = "You're up to date!";
  @Input() titleColor: string = 'var(--gh-color-dynamic-1)';
  @Input() iconName: string = 'task_alt';
  @Input() iconColor: string = 'var(--gh-color-dynamic-1)';
  @Input() description: string = "You don't have any pending requests";
  @Input() descriptionColor: string = 'var(--gh-color-letter)';

  constructor() { }

  ngOnInit() {
  }
}