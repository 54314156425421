import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar as MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-guesthub-input-upload-img',
  templateUrl: 'template.html',
  styleUrls: ['styles.scss']
})
export class MyComponent implements OnInit {
  @Input() img: string;
  @Input() ratio: number;
  @Input() containerName: string;
  @Input() required: boolean;
  @Input() id = 'input-upload-img';
  @Input() editable = true;
  @Input() noDelete = false;
  @Input() small = false;
  @Input() height: string;
  @Input() width: string;
  @Input() defaultIconMode = false;
  @Input() icon: string;
  @Input() maxSize = 5;
  @Input() resolution: string;
  @Output() onChangeImg = new EventEmitter();
  @Output() onError = new EventEmitter();
  @Output() onLoading = new EventEmitter();
  modalSelectDefaultIcon = false;
  loading: string;
  refreshInput = false;
  modalChangeImg = false;
  imageChangedEvent: string;

  constructor(private translateService: TranslateService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    if(!this.resolution) this.resolution = this.small ? '800x800' : '1980x1080';
  }

  deletePhoto() {
    this.img = null;
    this.refresh();
    this.onChangeImg.emit({
      id: null,
      urlToPreview: null
    })
  }

  getTranslatedText(): string {
    return this.translateService.instant(this.small ? 'JPG, PNG #ratio / #resolution Max. #maxMB' : 'JPG, PNG / #ratio / #resolution / Max. #maxMB')
      .replace('#ratio', this.ratio === 1 ? '1:1' : '16:9')
      .replace('#resolution', this.resolution)
      .replace('#max', this.maxSize).trim();
  }

  onComplete(input) {
    this.img = input.urlToPreview
    this.modalChangeImg = false;
    this.imageChangedEvent = null;
    this.refresh();
    this.onChangeImg.emit({
      id: input.id,
      urlToPreview: input.urlToPreview
    })
  }

  onErrorHandle(input) {
    console.error('Upload failed because: ', input)
    this.modalChangeImg = false;
    this.imageChangedEvent = null;
    this.refresh();
    this.openSnackBar(typeof input === 'string' ? input : "Upload failed because: " + JSON.stringify(input), "✖");
  
    this.onError.emit(input)
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: "right",
      verticalPosition: "top",
    });
  }

  loadingImg(input) {
    this.loading = input;
    this.refresh();
    this.onLoading.emit(input)
  }

  fileChangeEvent(event: any): void {
    if(event.target.files[0].size > this.maxSize * 1024 * 1024) {
      this.onErrorHandle("Uploaded File Exceeds Max Size");
      return;
    }
    this.imageChangedEvent = event;
  }

  updateImagenEvent(input) {
    this.img = input.imgUrl;
    this.onChangeImg.emit({
      id: input.img,
      urlToPreview: input.imgUrl
    })
    this.loadingImg('100');
    setTimeout(() => {
      this.loadingImg(null)
      this.refresh();
    }, 500)
  }

  triggerUpload(triggerDefaultIcon: boolean) {
    if (triggerDefaultIcon) {
      this.modalSelectDefaultIcon = true;
    } else {
      fetch(this.img).then(response => {
        if ((this.img && response.ok) && !this.img.includes('.svg')) this.modalChangeImg = true
        else document.getElementById(this.id).click();
      }).catch(() =>
        document.getElementById(this.id).click()
      )
    };
  }

  refresh() {
    this.refreshInput = true;
    setTimeout(() => {
      this.refreshInput = false;
    }, 50)
  }

}