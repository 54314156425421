import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterContentChecked,
  Inject
} from "@angular/core";
import { environment } from "../environments/environment";
import { detectBody } from "./app.helpers";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ResizedEvent } from "angular-resize-event";
import { NavigationHeaderTitleService } from "./navigation/top/header-title/service";
import { Auth0Service } from "./auth0.service";
import { ManagerColorService } from "./manager-colors.service";
import { EventsService } from "./events.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  host: {
    "(window:resize)": "onResize()"
  }
})
export class AppComponent implements OnInit, AfterContentChecked {
  /** Component Page Title */
  getFullYear: number = new Date().getFullYear();
  shouldDisplayOverlay: boolean = true;
  title = "GuestHub";
  environmentName = environment.envName;
  /** Bootstrap Bar for small devices */
  public isCollapsed = true;
  translate: any = null;
  //guidesService: any;
  showMenuMovil: boolean = false;
  params: any = {
    fixed: false,
    mininal: false
  };
  currentUrl: string;
  property: string;
  infoSession: any;
  isVisible: boolean = false;
  href: boolean = false;
  showMenu: boolean = true;
  logo: boolean = true;
  movil: boolean = false;
  loadUrlChat: any;
  widthMenu: any = "250px";
  movilIn: boolean = false;
  state: { features: { count: number } } = { features: { count: 23 } };
  heightDisplay: any;
  heightDisplayMenu: string;
  loading: boolean = true;
  /**
   * AppComponent Class Constructor.
   * @param ngRedux Instance of NgRedux
   * @param actions Main App actions
   * @param devTools Redux DevTools chrome extension
   * @param ngReduxRouter Redux Router
   * @param newreqEpics NewReq Middleware
   * @param keyboardEpics Keyboard Middleware
   * @param reqTypeEpics ReqType Middleware
   * @param additionalEpics  Additional Middleware
   */

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    //guidesService: GuidesService,
    translate: TranslateService,
    private serviceNavigationHeader: NavigationHeaderTitleService,
    public auth: Auth0Service,
    private changeDetector: ChangeDetectorRef,
    private managerColorService: ManagerColorService,
    @Inject("EventsService") private eventsService: EventsService,
  ) {
    this.translate = translate;
    //this.guidesService = guidesService;

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (localStorage.getItem("lang")) {
      translate.use(JSON.parse(localStorage.getItem("lang")).lang);
    } else {
      translate.use(window.navigator.language.substr(0, 2));
    }

    /** Store Configuration
     * This method is called only once in the application
     * @param rootReducer App reducers combined
     * @param init Initial State
     * @param epics Optinal Middleware
     * @param devTools Redux developer tool
     */
  }

  get windowRef() {
    return window;
  }

  onActivate(event) {
    document.getElementById("routeroutlet").scrollTop = 0;
    document.getElementById('containerRouter').scrollTop = 0;
    setTimeout(() => {
      this._setPositionFooterBootom();
    }, 1000);
    window.onload = function () {
      setTimeout(() => {
        this._setPositionFooterBootom();
      }, 1000);
    }.bind(this);
    window.addEventListener('resize', () => {
      this._setPositionFooterBootom();
    });
  }

  //ng-init
  ngOnInit() {
    this.loading = true;
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.redirectTo && queryParams.propertyId) {
        localStorage.setItem('chosen_property', queryParams.propertyId);
        const newQuery = Object.assign({}, queryParams, {
          redirectTo: undefined,
          propertyId: undefined
        });
        this.router.navigate([], {
          queryParams: newQuery
        });
      }
    });

    this.auth.handleAuthentication().then(() => {
      this.managerColorService.managerColor.subscribe(color => {
        Object.keys(color).forEach((key) => {
          (document.querySelector(":root") as any).style.setProperty(key, color[key]);
        });
      });
      this.managerColorService.setColor();

      this.eventsService.initNew(this.auth.getChosenProperty.toString());

      // this.loading = false;
      if (
        this.windowRef.innerWidth <= 1000 &&
        this.windowRef.innerWidth > 768
      ) {
        this.showMenu = false;
        this.logo = true;
        this.movil = false;
      } else if (this.windowRef.innerWidth <= 768) {
        this.movil = true;
        this.showMenu = false;
        this.logo = true;
      } else {
        this.showMenu = true;
        this.logo = true;
        this.movil = false;
      }
      this.heightDisplay = (this.windowRef.innerHeight - 90).toString() + "px";
      this.heightDisplayMenu =
        (this.windowRef.innerHeight - 90).toString() + "px";
      this.infoSession = JSON.parse(localStorage.getItem("guesthub_context"));
      this.property = localStorage.getItem("chosen_property");

      if (!JSON.parse(localStorage.getItem("lang"))) {
        const lng = {
          lang: this.translate.getBrowserLang()
        };
        localStorage.setItem("lang", JSON.stringify(lng));
      }

      detectBody();

      this.serviceNavigationHeader.subscribe((params) => {
        this.params = params;
      });
      this.loading = false;
    });
  }

  private _setPositionFooterBootom() {
    if (document.getElementById('bottom-footers')) {
      document.getElementById('bottom-footers').style.width = ((document.body ? document.body.clientWidth : 0) - (document.getElementById('navbnsar-sidebar') ? document.getElementById('navbnsar-sidebar').clientWidth : 0) - 1) + "px";
      document.getElementById('containerRouter').style.height = "87vh";
    } else {
      document.getElementById('containerRouter').style.height = null;
    }
  }

  sidebar(e) {
    if (this.windowRef.innerWidth < 1000) {
      this.movil = true;
      this.movilIn = true;
      this.showMenu = false;
      this.logo = false;
      this.showMenuMovil = !this.showMenuMovil ? true : !e ? true : false;
    } else {
      this.showMenuMovil = false;
      this.showMenu = e;
      this.logo = !e ? true : false;
      this.movilIn = false;
      this.movil = false;
    }
    this.heightDisplay = (this.windowRef.innerHeight - 90).toString() + "px";
    this.heightDisplayMenu =
      (this.windowRef.innerHeight - 90).toString() + "px";
  }
  outMenu() {
    this.showMenuMovil = false;
  }
  outResizeMenuMovil() {
    this.movilIn = false;
  }

  onResize(event: ResizedEvent) {
    this.heightDisplay = (this.windowRef.innerHeight - 70).toString() + "px";
    this.heightDisplayMenu =
      (this.windowRef.innerHeight - 85).toString() + "px";
    if ((event && event.newWidth < 1000) || this.windowRef.innerWidth < 1000) {
      this.showMenu = false;
      this.logo = true;
      this.movil = true;
      this.showMenuMovil = this.showMenuMovil;
    } else {
      this.showMenu = this.movil
        ? true
        : this.showMenu
          ? this.showMenu
          : this.showMenu;
      this.movil = false;
      this.logo = this.logo;
    }

    detectBody();
  }
  ngAfterContentChecked(): void {
    this.currentUrl = this.router.url
    this.changeDetector.detectChanges();
  }
}
