<div *ngIf="!small" id="cover-photo" [ngClass]="{'errorImgBorder': required && !img }" class="dragdropImage">
   <input *ngIf="!refreshInput" [id]="id" style="display: none;" type="file" (change)="fileChangeEvent($event)"
      accept="image/x-png,image/jpeg">
   <div *ngIf="img && !loading" class="dragdropImage__photo">
      <img (click)="triggerUpload(defaultIconMode)" class="dragdropImage__image" [ngClass]="{'img_icons' : img.includes('.svg')}" [src]="img" />
      <div *ngIf="editable && (!noDelete && !required)" class="icon-borderwhite--delete" (click)="deletePhoto()">
         <i class="material-icons icon-borderwhite__icon" tooltip="{{ 'Delete' | translate}}">delete</i>
      </div>
   </div>
   <div *ngIf="!img && !loading" (click)="triggerUpload(defaultIconMode)" class="dragdropImage__containerdescription">
      <span *ngIf="!loading" class="dragdropImage__description"> {{ 'Upload an image' | translate }} </span>
      <span *ngIf="!loading" class="dragdropImage__files"> 
         {{ getTranslatedText() }}
      </span>
   </div>

   <div *ngIf="loading" style="height: 100% !important;" class="dragdropImage__containerdescription">
      <app-guesthub-component-skeleton-loader width="100%" height="100%"></app-guesthub-component-skeleton-loader>
   </div>

   <div *ngIf="editable && !loading" (click)="triggerUpload(defaultIconMode)" class="icon-borderwhite">
      <i class="material-icons icon-borderwhite__icon" tooltip="{{ 'Upload image' | translate}}">camera_alt</i>
   </div>
</div>

<div *ngIf="small" class="dragdropImage__input">
   <div *ngIf="loading" class="dragdropImage__input__div">
      <app-guesthub-component-skeleton-loader width="100%" height="100%"></app-guesthub-component-skeleton-loader>
   </div>

   <div *ngIf="!loading" class="dragdropImage__input__div">
      <input *ngIf="!refreshInput" [id]="id" style="display: none;" type="file" (change)="fileChangeEvent($event)"
         accept="image/x-png,image/jpeg">
      <img id="logo-photo" [ngClass]="{'errorImgBorder': required && !img, 'padding30' : img.includes('.svg') }" *ngIf="img" [src]="img"
         (click)="triggerUpload(defaultIconMode)" class="img_base pointer" />
         <span *ngIf="!img" class="description_small"> 
            {{ getTranslatedText() }}
         </span>
      <div *ngIf="img && (!noDelete && !required)" class="icon-borderwhite icon-bord--delete" (click)="deletePhoto()">
         <i class="material-icons icon-borderwhite__icon" tooltip="{{ 'Delete' | translate}}">delete</i>
      </div>
      <div class="img_base_camera pointer" (click)="triggerUpload(defaultIconMode)">
         <i class="material-icons alt_camera" tooltip="{{ 'Upload image' | translate}}" container="body"
            placement="top">camera_alt</i>
      </div>
   </div>
</div>

<app-guesthub-component-modal-change-imagen [imageChangedEvent]="imageChangedEvent" [maxSize]="maxSize"
   *ngIf="imageChangedEvent || modalChangeImg" [ratio]="ratio" [image]="img" [containerName]="containerName"
   [ngStyle]="{'display': loading ? 'none' : 'block'}" (onComplete)="onComplete($event)" (onError)="onErrorHandle($event)"
   (closeModal)="modalChangeImg = false; imageChangedEvent = null" [height]="height" [width]="width"
   (loading)="loadingImg($event)"></app-guesthub-component-modal-change-imagen>

<app-guesthub-select-default-icon *ngIf="modalSelectDefaultIcon" [img]="{ img:icon, url:img }"
   (closeModal)="modalSelectDefaultIcon = false" (update)="updateImagenEvent($event)"
   (uploadOtherImagen)="triggerUpload(); modalSelectDefaultIcon = false"></app-guesthub-select-default-icon>