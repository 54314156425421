<div class="modal_right_0 white up_right mygavete">
    <div class="modal-content text-inicial white padding15 padding40">
        <div *ngIf="!loading">
            <div class="col s12 m12 l12 padding0">
                <i class="material-icons right pointer color-base-two clear_modal" (click)="closeModalEvent()">clear</i>
            </div>
            <div class="col s12 m12 l12 xl12 padding0">
                <div class="col s12 m12 xl12 padding0">
                    <span class="font24 color-base left semiblod">
                        {{'Select or upload an image' | translate}}
                        <br />
                        <br />
                    </span>
                </div>
                <div class="col s12 l12 xl12 padding10 border-dashed center pinter margin-bottom15 margin_top_buttom pointer"
                    (click)="uploadOtherImagenEvent()">
                    <a class="btn-floating margin_custon">
                        <i class="material-icons">add</i>
                    </a>
                    <a class="font_24_green_ligth">{{'Upload image' | translate}}</a>
                </div>
            </div>
            <div class="col s12 l12 m12 xl12 padding0">
                <div class="col s12 l12 m12 xl12 padding0">
                    <span class="font12 color-base-two">
                        {{'or select an image from our Gallery' | translate | uppercase }}
                    </span>
                </div>
                <div class="col s12 l12 xl12 m12"><br></div>
                <div class="pointer center" *ngFor="let item of imgesList"
                    [ngClass]="{'slect cuadre_select': selected == item.img, 'logo cuadre': selected != item.img}">
                    <img (click)="selected = item.img;" [src]="item.href"
                        class="card-module__img__icon grey_filter center" />
                </div>
            </div>
            <div class="col s12 l12 m12 xl12 padding0">
                <br />
                <div class="col s12 l12 m12 xl12 padding0" *ngIf="selected">
                    <div class="col s12 l12 xl12 m12 padding0 font14 color-base-two padding-top16 fix-action">
                        <a class="btn btn-small pointer font16 right" [ngClass]="{disabled: !selected}"
                            (click)="saveImg()">
                            {{'Save' | translate | titlecase }}
                        </a>

                        <a class="font16 color-base-two semiblod btn-cancel-right right" (click)="closeModalEvent()">
                            {{'Cancel' | translate}}</a>
                    </div>
                    <div class="col s12 l2 m12"><br /><br /><br /></div>
                </div>
            </div>
        </div>

        <div class="col s12 l12 xl12 m12 center">

            <div class="loadingdiv" *ngIf="loading">
                <br>
                <br>
                <br>
                <br>
                <br>
                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-green-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal-overlay pointer ups" (click)="closeModalEvent()"></div>